import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getNavigationState } from '../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import { history } from '../../state/store';

/**
 * SideBar Helper Component
 * 
 * Basic Functionality :
 *  - Create a modifiable list element with :
 *      - Name
 *      - icon
 *      - toggled
 *      - backgroundcolor
 *  - Possible submenu elements :
 *      - Icon
 *      - Name
 * **/
function SidebarButton(props) {

    function navigateToLink() {
        if(props.route) {
            if(props.route.includes('http')) {
                window.open(props.route, '_blank');
            }else {
                history.push(props.route)
            }
        }
    }

    return(
        <li className={props.parent.navigationState.view === props.toggled ? "active" : ""}>
            <a style={{cursor: 'pointer'}} onClick={navigateToLink} id={props.toggled} target={props.target}>
                <div><span><i><FontAwesomeIcon icon={props.icon} /></i></span> <span>{props.name}</span></div>    
            </a>
        </li>
    );

}

const mapStateToProps = state => ({
    navigationState: getNavigationState(state),
});

export default connect(
    mapStateToProps,
)(SidebarButton);