import React, {useEffect, useState} from 'react';
import { getMapState,setMapDetailHide,getNavigationState} from '../../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import MoreDetails from './MoreDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreDetailsTable from './MoreDetailsTable'
import { Tabs, Tab } from "react-bootstrap";
import NewProject from '../../../components/Project/NewProject';
import { getUserCadastreData } from '../../../state/reducers/user';
import ExportDataModal from '../../Project/_helper/exportDataModal';

const classes = (navigationState) => {
    
	let classes='';

	if(navigationState.detail) {
		classes = ' displayed';
	}
	else {
		classes = '';
	}
    
	return classes;

};

//Possibilities: 1 feature, 1 cluster, kijelölt több
function Details(props) {

    const [warning, setWarning] = useState("");
    const [selectedFeatures, setSelectedFeatures] = useState(false);
    const [selectedFeatureCadastreRefs, setSelectedFeatureCadastreRefs] = useState([]);
	const [newProjectModalShow, setNewProjectModalShow] = useState(false);
	const [show, setShow] = useState(false)

    // useffect to check navigation state change for selected markers
    useEffect(() => {
		let markerdata = props.navigationState.markerdata;
		
        //select with interaction
        if(markerdata.length) {
            setSelectedFeatures(markerdata)
           
			let unique = [...new Set(markerdata.map(x => x.values_.cadastre_id))];
            setSelectedFeatureCadastreRefs(unique)
        }
        //Cluster
        else if(markerdata.values_.hasOwnProperty("features")) {
            setSelectedFeatures(markerdata.values_.features);
            let unique = [...new Set(markerdata.values_.features.map(x => x.values_.cadastre_id))];
            setSelectedFeatureCadastreRefs(unique)
        }

        //One selected
        else {
            setSelectedFeatures([markerdata])
            setSelectedFeatureCadastreRefs([markerdata.values_.cadastre_id]);
        }

    }, [props.navigationState.markerdata]);

	function hideDetailLocal() {
		props.removeSelectedItem(selectedFeatures, null);
		props.hideDetail()
		setWarning("")
		props.clearDraw();
		props.updateFeature(false)
		props.setShowdetails(true)
	}

 	return (
		<div className={(!props.showdetails ? 'minimized ' : '') + 'card detail' + classes(props.navigationState)} style={{width:'480px'}}>
			<div className="card-header align-items-center" ref={props.refDrag}>
				<div className="color" style={{backgroundColor: '#99d7d8'}}></div>
				<div className="header-item">
					<div>{selectedFeatures.length} Kiválaszott elem</div>
					<div><small>Státusz: {null}</small></div>
				</div>
				<div>
					<div title="Export készítése" className="project-sidebar-export sidebar-tooltip">
						<i role="button" onClick={() => setShow(true)}><FontAwesomeIcon size="sm" icon={['fas', 'file-alt']}/></i>
					</div>
				</div>
				<div className="sidebar-tooltip" onClick={() =>{ setNewProjectModalShow(!newProjectModalShow) }}>
					<a href="#new"><i><FontAwesomeIcon size="1x" color="#4caeef" icon={['fas', 'plus']}/></i></a>
				</div>	
				<div className="minimize"><FontAwesomeIcon size="1x" color="#bfbfbf" icon={['far', props.showdetails ? 'window-minimize' : 'window-maximize'] } onClick={() => {props.setShowdetails(!props.showdetails)}}/></div>			
				<div onClick={hideDetailLocal} className="close"><Close/></div>
				<div style={{position: "absolute",top: "58px",fontSize: "11px",right: "10px", color:"red"}}>{warning}</div>
			</div>
			{newProjectModalShow && <NewProject show={newProjectModalShow} setShow={setNewProjectModalShow} selectedFeatures={selectedFeatures} highlighted={props.highlighted}/>}

			{(props.showdetails && selectedFeatures )&&
                <div style={{cursor:'default'}} className="card-body">
                    <div className="map-detail-items">
                            {selectedFeatures.length > 1 ?
                                <Tabs defaultActiveKey={selectedFeatureCadastreRefs.length ? selectedFeatureCadastreRefs[0] : "0"} style={{}}>
                                    {selectedFeatureCadastreRefs.filter(x => x !== null).map(cadastre_id => {
                                        return(
                                            <Tab className="cadastre-tab" eventKey={cadastre_id} title={props.cadastreData[cadastre_id].cadastre_label}>
                                                <MoreDetailsTable cadastre_id={cadastre_id} markerdata={selectedFeatures} removeSelectedItem={props.removeSelectedItem} zoomToFeature={props.zoomToFeature}/>
                                            </Tab>
                                        );
                                    })}
                                </Tabs>
                            :
                                <MoreDetails data={selectedFeatures[0]} zoomToFeature={props.zoomToFeature} />
                            }			
                    </div>
                </div>
			}
			{show && <ExportDataModal type='map' show={show} setShow={setShow} selectedFeatures={selectedFeatures} selectedFeatureCadastreRefs={selectedFeatureCadastreRefs} />}
		</div>
	);
};

const mapDispatchToProps = dispatch => ({
	hideDetail: () => {
		dispatch(setMapDetailHide());
	}
});

const mapStateToProps = state => ({
	cadastreData: getUserCadastreData(state),
	mapState: getMapState(state),
	navigationState: getNavigationState(state),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Details);