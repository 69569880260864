import Config from "../config/config";

/**
 * @class OpenStreet
 */
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
export class OpenStreet {
    /**
     * @constructor
     */
    constructor() {
        this.cityName = '';

        this.settings = {
            url: Config.endpoints.openStreetSearchBaseUrl,

            params: {
                q: '',
                format: 'json',
                addressdetails: 1,
                limit: 5,
                countrycodes: 'hu',
                'accept-language': 'en-US'
            },
        };
    }

    setCity(city) {
        this.cityName = city;
    }

    getParameters(opt) {

        return {
            url: this.settings.url,
            params: {
                q: this.cityName ? `${this.cityName} ${opt.query}` : opt.query,
                format: this.settings.params.format,
                addressdetails: this.settings.params.addressdetails,
                limit: opt.limit || this.settings.params.limit,
                countrycodes: opt.countrycodes || this.settings.params.countrycodes,
                'accept-language': opt.lang || this.settings.params['accept-language'],
            },
        };
    }

    handleResponse(results) {
        if (results.length === 0) {
            Toastify({
                text: "Nincs találat a keresett kifejezésre",
                close: true,
                stopOnFocus: false, 
                duration: 3000,
                style: {
                    background: "#f06640",
                },
            }).showToast();

            return [];
        }

        const filteredResults = results.filter(result => true);
        // const filteredResults = results.filter(result => result.addresstype !== "shop" ||
        //                                                  result.addresstype !== "amenity" ||
        //                                                  result.addresstype !== "office" ||
        //                                                  result.addresstype !== "tourism");

        if (filteredResults.length === 0) return [];

        return filteredResults.map((result) => ({
            lon: result.lon,
            lat: result.lat,
            bbox: result.boundingbox,

            address: {
                name: result.display_name,
                road: result.address.road || '',
                houseNumber: result.address.house_number || '',
                postcode: result.address.postcode,
                city: result.address.city || result.address.town,
                state: result.address.state,
                country: result.address.country,
            },

            original: {
                formatted: result.display_name,
                details: result.address,
            },
        }));
    }
}