import React, { useState } from 'react';
import Config from '../../config/config';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import { useTranslation } from 'react-i18next';
import PharosLogo from "../../assets/logos/pharos-logo-dark.webp";
import PropTypes from 'prop-types';

const LoginPharos = ({ setToken }) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    async function LoginUser(credentials) {
        const url = Config.pharosEndpoints.base;
        const authEndpoint = Config.pharosEndpoints.auth;
        return fetch(url + authEndpoint, {
            method: 'POST',
            dataType: "json",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(credentials)
        }).then(data => data.json())
            .then(function (json) {
                if (json.token) {
                    setMessage('Sikeres belépés, 5 másodperc alatt megtörténik az átiranyítás a Pharos dashboard oldalára!');
                    setToken(json.token);
                    setTimeout(function () {
                        window.location.reload();
                    }, 5000);
                }
                else {
                    console.log(json)
                    setMessage(json[0])
                }
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await LoginUser({
            username,
            password
        })
    }

    return (
        <BrowseLayout>
            <div className="pharos-container">
                <div className="pharos-logo"><img alt="Pharos" src={PharosLogo} /></div>
                <div className="login-container">
                    <h1 className="login-title">Datalight Pharos Login</h1>
                    <form onSubmit={handleSubmit} id="myForm" className="form-data">
                        <input className="login-input" type="text" placeholder={t('Username')} required="required" onChange={(e) => setUserName(e.target.value)} id="username" />
                        <input className="login-input" type="password" placeholder={'Jelszó'} required="required" onChange={(e) => setPassword(e.target.value)} id="password" />
                        <div><button className="btn-submit" type="submit">Belépés</button></div>
                    </form>
                    <p id="loginResult">{message}</p>
                </div>
            </div>
        </BrowseLayout>
    );
}

LoginPharos.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default LoginPharos;
