import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { history } from '../../state/store';
import renderHTML from 'react-render-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreIconColor from "../../assets/icons/more_with_color.svg";

export default function DashboardBox(props) {

    function navigateToLink() {
        if(props.route) {
            if(props.route.includes('http')) {
                window.open(props.route, '_blank');
            }else {
                history.push(props.route)
            }
        }
    }
    
    return(
        <Container className="dashboard-card-body" onClick={props.type !== "new" ? navigateToLink : undefined} >
            <div className="dashboard-card-inside">
                <Row className="dashboard-card-header">
                    {props.active && 
                    <Col lg="12">
                        <i role="button" className="dashboard-card-button" onClick={() => { navigateToLink() }}>...</i>
                    </Col>}
                    <Col lg="12" className="dashboard-header-col">
                        <p className="dashboard-card-type">{props.type}</p>
                        <p className="dashboard-card-title" style={{color: props.active ? "black" : "#ababab"}}>{props.title}</p>
                        <div className="dashboard-card-desc">{renderHTML(props.body)} {props.children} </div>
                    </Col>
                </Row>
                <div className="dashboard-card-footer">
                    {props.smart ?
                    <>
                        <Row className="dashboard-card-footer-header">
                            <Col sm={12} md={6} lg={7} xl={7} xs={12/props.smart.data.length} className="footer-title">Utolsó módosítás</Col>
                            {props.smart.title.map((title, index) => {
                                return(<Col key={index} sm={12} md={6} lg={5} xl={5} xs={12/props.smart.data.length}>{title}</Col>);
                            })}
                        </Row>
                        <Row className="dashboard-card-footer-details">
                            {console.log(props.smart.data[0])}
                            {props.smart.data.map((data, index) => {
                                return(
                                    <div key={index} className="footer-details-answer">
                                        <Col sm={12} md={6} lg={7} xl={7} xs={12/props.smart.data.length}>{data}</Col>
                                    </div>
                                );
                            })}
                        </Row>
                    </>
                    :
                    null
                    }
                </div>
            </div>
        </Container>
    )
}